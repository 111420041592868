<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px">
    <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="10000" @clearToast="clearToast" />
    <main class="page-content w-99" style="height: 100%">
      <div class="container-fluid row" style="height: 10%">
        <ul class="breadcrumb ProximaNovaRegular col" style="margin-left: 52px; margin-top: 12px">
          <li>
            <router-link to="/solutions/ul/platform/allstats">
              <span>Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/ul/platform/stat">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/dp/platform/future-planning">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/ul/future-planning/planning-results">
              <span>Planning Result</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Scenerio planning</li>
        </ul>
        <!--<div
          class="col-md-2 colo-lg-2 d-flex justify-center"
          v-if="activeTab === 'Additional Details'"
        >
          <div class="w-100 py-4">
            <w-dropdown
              :options="marketTypedropdown"
              :placeHolder="'Select'"
              :labelText="'Market type'"
              class="mr-3"
              textWidth="50%"
              dropdownWidth="50%"
              bgColor="#fff"
              labelColor="#727988"
              boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
            ></w-dropdown>
          </div>
        </div>-->
      </div>

      <div class="d-none flex-row justify-content-end" style="height: 40%">
        <div class="pt-4">
          <w-white-dropdown :options="brandDropdown" :placeHolder="'Select'" :selectedOption="selectedBrand"
            :labelText="'Brand'" class="mr-3" @input="selectBrand($event)"></w-white-dropdown>
        </div>

        <div class="pt-4">
          <w-white-dropdown :options="mediaDropdown" :placeHolder="'Select'" :selectedOption="selectedMedia"
            :labelText="'KPI'" class="mr-3" @input="selectMedia($event)"></w-white-dropdown>
        </div>
      </div>

      <!-- tab -->
      <div class="container-fluid mt-5" style="height: 10%">
        <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
        </w-tab>
      </div>
      <!-- Digital -->
      <div class="w-100 pb-4 mt-3 prediction-section" v-if="activeTab === 'Digital Media'" style="height: 100%">
        <div class="w-100" style="">
          <div class="" style="margin-top: 10px">
            <div class="card w-100" style="box-shadow: rgb(233 235 239) 1px 2px 13px 5px">
              <div class="card-body p-4">
                <div class="card-header font-large ProximaNovaBold"
                  style="padding: 0; background: transparent; border: 0px !important">
                  * Prediction

                  <!-- <div class="float-right text-normal ProximaNovaRegular">
                    <h3>
                      Total Predicted Budget -
                      <strong class=" ml-2 mr-5">
                        {{ levelOneChecking.value}}</strong
                      >
                    </h3>
                    <div class="d-flex flex-row mt-5">
                        <h3>
                     L1 total -
                      <strong class="ml-2 mr-5">
                        {{ formatValue(levelOneInputCheck.value) }}</strong
                      >
                    </h3>
                       <h3>
                     L2 total -
                      <strong class="ml-2 mr-5">
                        {{ formatValue(levelOneInputCheck.value) }}</strong
                      >
                    </h3>
                       <h3>
                     L3 total -
                      <strong class="ml-2 mr-5">
                        {{ formatValue(levelOneInputCheck.value) }}</strong
                      >
                    </h3>
                    </div>

               
                  </div> -->

                  <div class="float-right text-danger text-normal ProximaNovaRegular d-none">
                    <span v-if="levelOneChecking.value !== 100">* Total growth is {{ levelOneChecking.value }} in
                      {{ levelOneChecking.name }}
                    </span>
                    <span v-else-if="checkLevelTwo.value !== 100">* Total growth is {{ checkLevelTwo.value }} in
                      {{ checkLevelTwo.name }}
                    </span>
                    <span v-else-if="checkLevelThree.value !== 100">* Total growth is {{ checkLevelThree.value }} in
                      {{ checkLevelThree.name }}
                    </span>
                    <span v-else>* Total growth is 100</span>
                  </div>

                  <div class="caption mt-3">
                    <span :class="
                      breadcrumb.first !== '' &&
                        breadcrumb.second === '' &&
                        breadcrumb.third === ''
                        ? 'text-bold'
                        : ''
                    ">{{ breadcrumb.first }}</span>
                    <span v-if="breadcrumb.second" :class="
                      breadcrumb.first !== '' &&
                        breadcrumb.second !== '' &&
                        breadcrumb.third === ''
                        ? 'text-bold'
                        : ''
                    "><i class="fa fa-chevron-right mx-3"></i>{{ breadcrumb.second }}</span>
                    <span v-if="breadcrumb.third" :class="
                      breadcrumb.first !== '' &&
                        breadcrumb.second !== '' &&
                        breadcrumb.third !== ''
                        ? 'text-bold'
                        : ''
                    "><i class="fa fa-chevron-right mx-3"></i>{{ breadcrumb.third }}</span>
                  </div>
                </div>

                <w-button :buttonLoader="'normal'" :buttonText="`View in ${this.switchText}`" class="my-5 d-none"
                  @buttonClicked="handleSwitch">
                </w-button>
              </div>
            </div>
          </div>
        </div>

        <!--Cards-->
        <div v-if="showInPercentage" class="container-fluid tab-panel card__body_content pt-5" style="height: 90%">
          <div class="row fix-width">
            <div class="col p-0">
              <div @click="toggleOpenButton(i)" class="newcard" :class="[
                i === activeGraph.activeFirst
                  ? 'border border-dark'
                  : 'disabled_card',
                activeGraph.activeFirst === null ? 'default' : '',
              
                i === activeGraph.activeFirst &&
                  info.data &&
                  info.data.length > 0
                  ? 'border_right_side'
                  : '',
              ]" v-for="(info, i) in levelOne" :key="i">
                <div class="mb-5" style="padding: 0px 12px">
                  <span class="slider_text">
                    <img v-if="info.title === 'Instagram'" src="@/assets/Icons/insta.svg" alt="" class="mr-2" />
                    <img v-if="info.title === 'DV360'" src="@/assets/Icons/bitmap.png" alt="" class="mr-2" />
                    <img v-if="info.title === 'Linkedin'" src="@/assets/Icons/linkedin.svg" alt="" class="mr-2" />
                    <img v-if="info.title === 'Twitter'" src="@/assets/Icons/twitter.png" alt="" class="mr-2" />
                    {{ info.title }}
                  </span>
                  <span class="float-right slider_button_right">
                    <img v-if="!showMap[i]" class="eye-line" src="@/assets/eye-close-line.svg" alt="" />
                    <img v-else class="eye-line" src="@/assets/eye-line.png" alt="" />
                  </span>
                </div>
                <Slider class="px-3 mt-5" :defaultValue="info.value" :pathColor="info.pathColor"
                  :rangeColor="info.backgroundColor" @updatedValue="getSliderValue($event, info)" />
                <!-- <w-activitychart
                  :sliderValue="info.value"
                  :color="info.color"
                  @sliderChanged="getSliderValue($event, info)"
                ></w-activitychart> -->
                <div class="divider1" v-if="
                  i === activeGraph.activeFirst &&
                  levelOne[activeGraph.activeFirst].data
                " :style="
  activeGraph.activeSecond === 0
    ? `height:0`
    : `height:` + 336 * activeGraph.activeSecond + `px;`
"></div>
              </div>
            </div>
            <div class="col p-0" style="margin-top: 4px">
              <div v-if="activeGraph.activeFirst !== null">
                <div @click="toggleOpenSubButton(index)" class="newcard d-block position-relative" :style="
                  activeGraph.activeFirst === 0
                    ? `top:0`
                    : `top:` + 336 * activeGraph.activeFirst + `px;`
                " :class="[
  index === activeGraph.activeSecond
    ? 'border border-dark border_left_side1'
    : 'disabled_card',
  activeGraph.activeSecond === null ? 'default' : '',

  index === activeGraph.activeSecond &&
    infoData.data &&
    infoData.data.length > 0
    ? 'border_right_side'
    : '',
]" v-for="(infoData, index) in levelOne[activeGraph.activeFirst]
.data" :key="index">
                  <div class="mb-4" style="padding: 0px 12px">
                    <span class="slider_text">
                      <img v-if="infoData.title === 'Instagram'" src="@/assets/Icons/insta.svg" alt="" class="mr-2" />
                      <img v-if="infoData.title === 'DV360'" src="@/assets/Icons/bitmap.png" alt="" class="mr-2" />
                      <img v-if="infoData.title === 'Linkedin'" src="@/assets/Icons/linkedin.svg" alt="" class="mr-2" />
                      <img v-if="infoData.title === 'Twitter'" src="@/assets/Icons/twitter.png" alt="" class="mr-2" />
                      {{ infoData.title }}
                    </span>
                    <span class="float-right slider_button_right">
                      <img v-if="showMapSub[index]" class="eye-line" src="@/assets/eye-line.png" alt="" />
                      <img v-else class="eye-line" src="@/assets/eye-close-line.svg" alt="" />
                    </span>
                  </div>
                  <Slider class="px-3 mt-5" :defaultValue="infoData.value" :pathColor="levelOne.pathColor"
                    :rangeColor="levelOne.backgroundColor" @updatedValue="getSliderValueLevelTwo($event, infoData)" />
                  <!-- <w-activitychart
                    :sliderValue="infoData.value"
                    :color="infoData.color"
                    @sliderChanged="getSliderValueLevelTwo($event, infoData)"
                  ></w-activitychart> -->
                </div>
              </div>
            </div>
            <div class="col p-0" style="margin-top: 10px">
              <div v-if="activeGraph.activeSecond !== null">
                <div class="newcard d-block position-relative" :style="subsubStyle" v-for="(infoData, index) in levelOne[activeGraph.activeFirst]
                .data[activeGraph.activeSecond].data" :key="index" :class="index === 0 ? 'border_left_side1' : ''"
                  style="border: 2px solid #fff">
                  <div class="mb-4" style="padding: 0px 12px">
                    <span class="slider_text">
                      {{ infoData.title }}
                    </span>
                  </div>
                  <Slider class="px-3 mt-5" :defaultValue="infoData.value" :pathColor="levelOne.pathColor"
                    :rangeColor="levelOne.backgroundColor" @updatedValue="getSliderValueLevelThree($event, infoData)" />
                  <!-- <w-activitychart
                    :sliderValue="infoData.value"
                    :color="infoData.color"
                    @sliderChanged="getSliderValueLevelThree($event, infoData)"
                  ></w-activitychart> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showInValue" class="mt-5" style="height: 2500px">
          <div class="row fix-width">

            <div class="col p-0">
              <div @click="toggleOpenButton(i)" class="newcard" :class="[
                i === activeGraph.activeFirst
                  ? 'border border-dark'
                  : 'disabled_card',
                activeGraph.activeFirst === null ? 'default' : '',
              
                i === activeGraph.activeFirst &&
                  info.data &&
                  info.data.length > 0
                  ? 'border_right_side'
                  : '',
              ]" v-for="(info, i) in levelOne" :key="i">
                <div class="mb-4" style="padding: 0px 12px">
                  <span class="slider_text">
                    <img v-if="info.title === 'Instagram'" src="@/assets/Icons/insta.svg" alt="" class="mr-2" />
                    <img v-if="info.title === 'DV360'" src="@/assets/Icons/bitmap.png" alt="" class="mr-2" />
                    <img v-if="info.title === 'Linkedin'" src="@/assets/Icons/linkedin.svg" alt="" class="mr-2" />
                    <img v-if="info.title === 'Twitter'" src="@/assets/Icons/twitter.png" alt="" class="mr-2" />
                    {{ info.title }}
                  </span>
                  <span class="float-right slider_button_right">
                    <img v-if="!showMap[i]" class="eye-line" src="@/assets/eye-close-line.svg" alt="" />
                    <img v-else class="eye-line" src="@/assets/eye-line.png" alt="" />
                  </span>
                </div>
                <div class="d-flex justify-content-center">
                  <input style="
                      width: 100%;
                      height: 40px;
                      padding: 10px;
                      margin-left: 13px;
                      border-radius: 4px;
                      margin-right: 30px;
                      margin-top: 10px;
                      font-size: 14px;
                      background-color: rgb(239, 245, 255);
                      box-sizing: border-box;
                      border: 2px solid #ccc;
                      -webkit-transition: 0.5s;
                      transition: 0.5s;
                      outline: none;
                    " type="number" placeholder="Enter changed budget in millions"
                    @input="getLevelOneInput($event, info)" />
                </div>
                <div class="mr-5">
                  <div class="dataCard">
                    <div class="row">
                      <div class="col-md-8 col-8 pr-0">
                        <div class="d-flex justify-content-between">
                          <div class="Last-year-title py-2">
                            Predicted Budget
                          </div>
                          <span class="line"></span>
                        </div>
                      </div>
                      <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                        <span class="Last-year-value"> {{ info.value }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <Slider
                      class="px-3"
                      :defaultValue="info.value"
                      :pathColor="info.pathColor"
                      :rangeColor="info.backgroundColor"
                      @updatedValue="getSliderValue($event, info)"
                    /> -->
                <!-- <w-activitychart
                  :sliderValue="info.value"
                  :color="info.color"
                  @sliderChanged="getSliderValue($event, info)"
                ></w-activitychart> -->
                <div class="divider" v-if="
                  i === activeGraph.activeFirst &&
                  levelOne[activeGraph.activeFirst].data
                " :style="
  activeGraph.activeSecond === 0
    ? `height:0`
    : `height:` + 206 * activeGraph.activeSecond + `px;`
"></div>
              </div>
            </div>

            <div class="col p-0" style="margin-top: 4px">
              <div v-if="activeGraph.activeFirst !== null">
                <div @click="toggleOpenSubButton(index)" class="newcard d-block position-relative" :style="
                  activeGraph.activeFirst === 0
                    ? `top:0`
                    : `top:` + 336 * activeGraph.activeFirst + `px;`
                " :class="[
  index === activeGraph.activeSecond
    ? 'border border-dark border_left_side'
    : 'disabled_card',
  activeGraph.activeSecond === null ? 'default' : '',

  index === activeGraph.activeSecond &&
    infoData.data &&
    infoData.data.length > 0
    ? 'border_right_side'
    : '',
]" v-for="(infoData, index) in levelOne[activeGraph.activeFirst]
.data" :key="index">
                  <div class="mb-4" style="padding: 0px 12px">
                    <span class="slider_text">
                      <img v-if="infoData.title === 'Instagram'" src="@/assets/Icons/insta.svg" alt="" class="mr-2" />
                      <img v-if="infoData.title === 'DV360'" src="@/assets/Icons/bitmap.png" alt="" class="mr-2" />
                      <img v-if="infoData.title === 'Linkedin'" src="@/assets/Icons/linkedin.svg" alt="" class="mr-2" />
                      <img v-if="infoData.title === 'Twitter'" src="@/assets/Icons/twitter.png" alt="" class="mr-2" />
                      {{ infoData.title }}
                    </span>
                    <span class="float-right slider_button_right">
                      <img v-if="showMapSub[index]" class="eye-line" src="@/assets/eye-line.png" alt="" />
                      <img v-else class="eye-line" src="@/assets/eye-close-line.svg" alt="" />
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <input style="
                        width: 90%;
                        height: 40px;
                        padding: 10px;
                        margin-left: 0px;
                        margin-right: 10px;
                        border-radius: 4px;
                        margin-top: 10px;
                        font-size: 14px;
                        background-color: rgb(239, 245, 255);
                        box-sizing: border-box;
                        border: 2px solid #ccc;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                        outline: none;
                      " type="number" placeholder="Enter changed budget in millions"
                      @input="getLevelTwoInput($event, infoData)" />
                  </div>
                  <div class="mr-5">
                    <div class="dataCard">
                      <div class="row">
                        <div class="col-md-8 col-8 pr-0">
                          <div class="d-flex justify-content-between">
                            <div class="Last-year-title py-2">
                              Predicted Budget
                            </div>
                            <span class="line"></span>
                          </div>
                        </div>
                        <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                          <span class="Last-year-value">{{
                              infoData.value
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <Slider
                      class="px-3"
                      :defaultValue="infoData.value"
                      :pathColor="levelOne.pathColor"
                      :rangeColor="levelOne.backgroundColor"
                      @updatedValue="getSliderValueLevelTwo($event, infoData)"
                    /> -->
                  <!-- <w-activitychart
                    :sliderValue="infoData.value"
                    :color="infoData.color"
                    @sliderChanged="getSliderValueLevelTwo($event, infoData)"
                  ></w-activitychart> -->
                </div>
              </div>
            </div>

            <div class="col p-0" style="margin-top: 10px">
              <div v-if="activeGraph.activeSecond !== null">
                <div class="newcard d-block position-relative" :style="subsubStyle" v-for="(infoData, index) in levelOne[activeGraph.activeFirst]
                .data[activeGraph.activeSecond].data" :key="index" :class="index === 0 ? 'border_left_side' : ''"
                  style="border: 2px solid #fff">
                  <div class="mb-4" style="padding: 0px 12px">
                    <span class="slider_text">
                      {{ infoData.title }}
                    </span>
                  </div>

                  <!-- <Slider
                      class="px-3"
                      :defaultValue="infoData.value"
                      :pathColor="levelOne.pathColor"
                      :rangeColor="levelOne.backgroundColor"
                      @updatedValue="getSliderValueLevelThree($event, infoData)"
                    /> -->
                  <div class="d-flex justify-content-center">
                    <input style="
                        width: 90%;
                        height: 40px;
                        padding: 10px;
                        margin-left: 10px;
                        border-radius: 4px;
                        margin-right: 30px;
                        margin-top: 10px;
                        font-size: 14px;
                        background-color: rgb(239, 245, 255);
                        box-sizing: border-box;
                        border: 2px solid #ccc;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                        outline: none;
                      " type="number" placeholder="Enter changed budget in millions"
                      @input="getLevelThreeInput($event, infoData)" />
                  </div>
                  <div class="mr-5">
                    <div class="dataCard">
                      <div class="row">
                        <div class="col-md-8 col-8 pr-0">
                          <div class="d-flex justify-content-between">
                            <div class="Last-year-title py-2">
                              Predicted Budget
                            </div>
                            <span class="line"></span>
                          </div>
                        </div>
                        <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                          <span class="Last-year-value">{{
                              infoData.value
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <w-activitychart
                    :sliderValue="infoData.value"
                    :color="infoData.color"
                    @sliderChanged="getSliderValueLevelThree($event, infoData)"
                  ></w-activitychart> -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <w-scenarioplanning v-if="activeTab === 'Additional Details'"></w-scenarioplanning>
      <div class="float-right bottom_buttom" style="height: 10%">
        <w-button :buttonText="'Predict'" class="mb-5" :buttonLoader="buttonLoader"
          :class="{ resetPasswordLink: isActive }" @buttonClicked="handleClick">
        </w-button>
      </div>
    </main>
  </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
// import ActivityChart from "@/pages/DEMO/AIMO-UL/ActivityChart.vue";
import AdditionalDetails from "@/pages/Scenario/AdditionalDetails.vue";
import Button from "@/components/Profile/Button.vue";
import Toast from "@/components/Toast/Toast.vue";

// import { AIDigitalPoem } from "../../../services/AIDigitalPoemServices";
// import { color } from "highcharts";
// const aiDigitalPoem = new AIDigitalPoem();
// import Dropdown from "@/widgets/Dropdown.vue";
// import { color } from 'highcharts';
import Slider from "@/widgets/Slider.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import { abbreviateNumber } from "js-abbreviation-number";

import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();

export default {
  name: "ScenarioPlanning",
  components: {
    "w-tab": Tab,
    // "w-activitychart": ActivityChart,
    "w-scenarioplanning": AdditionalDetails,
    // "w-dropdown": Dropdown,
    "w-button": Button,
    Slider,
    Toast,
    "w-white-dropdown": WhiteDropdown,
  },
  data() {
    return {
      isForecasting: "",
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      budgetVariance: 0,
      levelOneTotal: [],
      showPredict: false,
      levelTwoTotal: [],
      levelThreeTotal: 0,
      enteredBudget: [],
      incommingData: "",
      selectedBrand: { id: "Tresemme", text: "Tresemme" },
      selectedMedia: { id: "Online", text: "Online" },
      switchText: "numbers",
      showInPercentage: false,
      showInValue: true,
      activelevelOne: "",
      buttonLoader: "normal",
      activeLevelTwo: "",
      marketTypedropdown: [
        { text: "USA", id: "1" },
        { text: "INDIA", id: "2" },
        { text: "UK", id: "3" },
      ],
      brandDropdown: [
        { text: "Tresemme", id: "tresemme" },
        { text: "Dove", id: "dove" },
      ],
      mediaDropdown: [
        { text: "Offline", id: "offline" },
        { text: "Online", id: "online" },
        // { text: "Overall", id: "overall" },
      ],
      breadcrumb: {
        first: "Channel",
        second: "",
        third: "",
      },
      activeTab: "Digital Media",
      activeGraph: {
        activeFirst: null,
        activeSecond: null,
      },
      tabs: [
        {
          category: "Digital Media",
        },
        // {
        //   category: "Content",
        // },
        // {
        //   category: "Additional Details",
        // },
      ],
      showMap: [],
      showMapSub: [],
      levelOne: [],
      subdata: [
        {
          id: 1,
          title: "Instagram",
          value: 20,
          color: "#1877f2",
          data: [
            {
              id: 1,
              title: "Engagement Campaigns",
              value: 25,
              color: "#b5a9ff",
            },
            {
              id: 2,
              title: "VIdeo Campaigns",
              value: 30,
              color: "#ffdd7c",
            },
            {
              id: 3,
              title: "Lead Ads",
              value: 41,
              color: "#67e8ff",
            },
          ],
        },
        {
          id: 2,
          title: "DV360",
          value: 50,
          color: "#dd2a7b",
          data: [
            {
              id: 1,
              title: "Engagement Campaigns",
              value: 25,
              color: "#b5a9ff",
              data: [
                {
                  id: 1,
                  title: "Lead Ads",
                  value: 50,
                  color: "#b5a9ff",
                },
                {
                  id: 2,
                  title: "Lead Ads",
                  value: 41,
                  color: "#ffdd7c",
                },
              ],
            },
            {
              id: 2,
              title: "VIdeo Campaigns",
              value: 30,
              color: "#ffdd7c",
              data: [
                {
                  id: 1,
                  title: "Lead Ads",
                  value: 50,
                  color: "#b5a9ff",
                },
                {
                  id: 2,
                  title: "Lead Ads",
                  value: 41,
                  color: "#ffdd7c",
                },
              ],
            },
            {
              id: 3,
              title: "Lead Ads",
              value: 41,
              color: "#67e8ff",
              data: [
                {
                  id: 1,
                  title: "Lead Ads",
                  value: 50,
                  color: "#b5a9ff",
                },
                {
                  id: 2,
                  title: "Lead Ads",
                  value: 41,
                  color: "#ffdd7c",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "Linkedin",
          value: 60,
          color: "#148f3a",
        },
        {
          id: 4,
          title: "Twitter",
          value: 80,
          color: "#0077b5",
        },
        {
          id: 5,
          title: "Instagram",
          value: 75,
          color: "#08a0e9",
        },
      ],
      colors: [
        "#1877f2",
        "#dd2a7b",
        "#148f3a",
        "#0077b5",
        "#b5a9ff",
        "#ffdd7c",
        "#08a0e9",
        "#dd2a7b",
        "#1877f2",
      ],
      levelOneDataEdit: [],
      digitalDataLevelTwo: [],
      contentDataLevelTwo: [],
      contentDataThirdLevel: [],
      digitalDataThirdLevel: [],
      actualValues: {},
      resData: {
        mediaData: [
          {
            levelOne: [
              {
                name: "digital",
                previous: 2121441.62,
                optimised: 1323748.38,
              },
              {
                name: "tv",
                previous: 876082.7,
                optimised: 164880.99,
              },
              {
                name: "digital_performance",
                previous: 1716526.69,
                optimised: 1858041.56,
              },
              {
                name: "print",
                previous: 356614.06,
                optimised: 10509.52,
              },
            ],
            levelTwo: [
              {
                name: "digital",
                value: [
                  {
                    name: "digital - display",
                    previous: 476005.16,
                    optimised: 348214.11,
                  },
                  {
                    name: "digital - fep",
                    previous: 451431.45,
                    optimised: 619922.67,
                  },
                  {
                    name: "digital - social",
                    previous: 769803.26,
                    optimised: 69269.38,
                  },
                  {
                    name: "digital - video",
                    previous: 424201.75,
                    optimised: 286342.23,
                  },
                ],
              },
              {
                name: "digital_performance",
                value: [
                  {
                    name: "commerce (display)",
                    previous: 224435.24,
                    optimised: 394709.94,
                  },
                  {
                    name: "commerce (search)",
                    previous: 1375748.56,
                    optimised: 1350200.94,
                  },
                  {
                    name: "digital search (brand.com)",
                    previous: 116342.89,
                    optimised: 113130.68,
                  },
                ],
              },
              {
                name: "print",
                value: [
                  {
                    name: "allure",
                    previous: 26067.21,
                    optimised: 0,
                  },
                  {
                    name: "cosmopolitan",
                    previous: 37087.23,
                    optimised: 0,
                  },
                  {
                    name: "elle",
                    previous: 42625.74,
                    optimised: 0,
                  },
                  {
                    name: "in style magazine",
                    previous: 92594.77,
                    optimised: 0,
                  },
                  {
                    name: "marie claire",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "others",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "people en espanol",
                    previous: 32562.83,
                    optimised: 0,
                  },
                  {
                    name: "people magazine",
                    previous: 42729.31,
                    optimised: 10509.52,
                  },
                  {
                    name: "shape",
                    previous: 57411.9,
                    optimised: 0,
                  },
                  {
                    name: "womens health",
                    previous: 25535.09,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "tv",
                value: [
                  {
                    name: "nbc universal inc",
                    previous: 249912.05,
                    optimised: 111459.31,
                  },
                  {
                    name: "others",
                    previous: 69864.76,
                    optimised: 52975.55,
                  },
                  {
                    name: "the walt disney co",
                    previous: 147630.1,
                    optimised: 0,
                  },
                  {
                    name: "turner broadcasting system inc",
                    previous: 85973.86,
                    optimised: 0,
                  },
                  {
                    name: "univision television group inc",
                    previous: 182954.72,
                    optimised: 0,
                  },
                  {
                    name: "viacom inc",
                    previous: 92861.31,
                    optimised: 446.12,
                  },
                  {
                    name: "walt disney tv",
                    previous: 46885.9,
                    optimised: 0,
                  },
                ],
              },
            ],
            levelThreeList: [
              {
                name: "digital - display",
                value: [
                  {
                    name: "conde nast digital",
                    previous: 74673.89,
                    optimised: 58217.72,
                  },
                  {
                    name: "nbcuni.com",
                    previous: 19133.71,
                    optimised: 67.2,
                  },
                  {
                    name: "others",
                    previous: 90538.86,
                    optimised: 87959.96,
                  },
                  {
                    name: "the trade desk inc",
                    previous: 291658.69,
                    optimised: 201969.24,
                  },
                ],
              },
              {
                name: "digital - fep",
                value: [
                  {
                    name: "cwtv.com",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "hulu",
                    previous: 287157.41,
                    optimised: 382321.84,
                  },
                  {
                    name: "mtv networks",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "nbc.com",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "others",
                    previous: 127198.07,
                    optimised: 124827.56,
                  },
                  {
                    name: "peacock",
                    previous: 37075.98,
                    optimised: 112773.27,
                  },
                  {
                    name: "viacomcbs",
                    previous: 0,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "digital - social",
                value: [
                  {
                    name: "conde nast digital",
                    previous: 307.05,
                    optimised: 0,
                  },
                  {
                    name: "facebook.com",
                    previous: 55556.36,
                    optimised: 54178.4,
                  },
                  {
                    name: "hearst network",
                    previous: 677698.73,
                    optimised: 15083.1,
                  },
                  {
                    name: "pinterest",
                    previous: 3503.27,
                    optimised: 0.24,
                  },
                  {
                    name: "tiktok",
                    previous: 32737.85,
                    optimised: 7.64,
                  },
                  {
                    name: "twitter",
                    previous: 0,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "digital - video",
                value: [
                  {
                    name: "buzzfeed",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "hearst network",
                    previous: 334608.75,
                    optimised: 181930.83,
                  },
                  {
                    name: "hulu",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "mtv networks",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "others",
                    previous: 68612.65,
                    optimised: 64898.85,
                  },
                  {
                    name: "studio 71,lp",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "the trade desk inc",
                    previous: 3100.05,
                    optimised: 7504.45,
                  },
                  {
                    name: "youtube.com",
                    previous: 17880.3,
                    optimised: 32008.1,
                  },
                ],
              },
              {
                name: "commerce (display)",
                value: [
                  {
                    name: "amazon.com",
                    previous: 218168.92,
                    optimised: 380035.31,
                  },
                  {
                    name: "media iq digital na",
                    previous: 0.03,
                    optimised: 0.02,
                  },
                  {
                    name: "others",
                    previous: 6266.29,
                    optimised: 14674.62,
                  },
                  {
                    name: "valassis digital",
                    previous: 0,
                    optimised: 0,
                  },
                  {
                    name: "walmart.com",
                    previous: 0,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "commerce (search)",
                value: [
                  {
                    name: "amazon.com",
                    previous: 341036.74,
                    optimised: 169582.39,
                  },
                  {
                    name: "others",
                    previous: 829707.72,
                    optimised: 1006545.62,
                  },
                  {
                    name: "target",
                    previous: 65426.61,
                    optimised: 108719.12,
                  },
                  {
                    name: "walmart.com",
                    previous: 139577.49,
                    optimised: 65353.81,
                  },
                ],
              },
              {
                name: "digital search (brand.com)",
                value: [
                  {
                    name: "bing",
                    previous: 91446.68,
                    optimised: 90435.93,
                  },
                  {
                    name: "google ads",
                    previous: 24896.21,
                    optimised: 22694.74,
                  },
                ],
              },
              {
                name: "allure",
                value: [],
              },
              {
                name: "cosmopolitan",
                value: [],
              },
              {
                name: "elle",
                value: [],
              },
              {
                name: "in style magazine",
                value: [],
              },
              {
                name: "marie claire",
                value: [],
              },
              {
                name: "others",
                value: [],
              },
              {
                name: "people en espanol",
                value: [],
              },
              {
                name: "people magazine",
                value: [],
              },
              {
                name: "shape",
                value: [],
              },
              {
                name: "womens health",
                value: [],
              },
              {
                name: "nbc universal inc",
                value: [
                  {
                    name: "cable",
                    previous: 157394.04,
                    optimised: 91199.17,
                  },
                  {
                    name: "others",
                    previous: 80541.15,
                    optimised: 20260.14,
                  },
                  {
                    name: "prime",
                    previous: 11976.85,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "others",
                value: [
                  {
                    name: "others",
                    previous: 69864.76,
                    optimised: 52975.55,
                  },
                ],
              },
              {
                name: "the walt disney co",
                value: [
                  {
                    name: "others",
                    previous: 147630.1,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "turner broadcasting system inc",
                value: [
                  {
                    name: "others",
                    previous: 85973.86,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "univision television group inc",
                value: [
                  {
                    name: "hispanic",
                    previous: 182954.72,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "viacom inc",
                value: [
                  {
                    name: "cable",
                    previous: 79236.67,
                    optimised: 446.12,
                  },
                  {
                    name: "others",
                    previous: 13624.64,
                    optimised: 0,
                  },
                ],
              },
              {
                name: "walt disney tv",
                value: [
                  {
                    name: "others",
                    previous: 46885.9,
                    optimised: 0,
                  },
                ],
              },
            ],
          },
        ],
        predictions: [
          {
            name: "Previous Budget",
            value: 5378253.2560749315,
          },
          {
            name: "Optimised Budget",
            value: 3014729.5248150546,
          },
          {
            name: "Previous Revenue",
            value: 5070665.0772943795,
          },
          {
            name: "Expected Revenue",
            value: 3357180.4571143487,
          },
          {
            name: "Change in Revenue",
            value: -33.79,
          },
          {
            name: "Change in Budget",
            value: -43.95,
          },
        ],
      },
    };
  },
  created() {
    this.getSliderData();
  },
  mounted() {
    // const scenarioPlanningData = this.$router.history.current.params.scenarioPlanningData;
    this.scearioData = JSON.parse(localStorage.getItem("optimizeData"));
    // this.incommingData = JSON.parse(localStorage.getItem("optimizeData"));
    // const digitalData = this.$route.params.levelOneData;
    // const digitalLevelTwo = this.$route.params.digitalData;
    // const digitalThirdLevelData = this.$route.params.digitalThirdLevelData;
    // this.digitalDataThirdLevel = digitalThirdLevelData;
    // this.digitalDataLevelTwo = digitalLevelTwo;
    // this.levelOneDataEdit = digitalData;
    // let data = [];
    // var secondLevelData = [];
    //   digitalData.forEach((ele, index) => {
    //     var i = 0;
    //     var j = 0;
    //     var dataLevelThree;
    //     if (ele.name === "Digital") {
    //       secondLevelData = [];
    //       for (i = 0; i < digitalLevelTwo.length; i++) {
    //         let levelthree = digitalThirdLevelData.find(
    //           (obj) => obj.title === digitalLevelTwo[i].label
    //         ).value;
    //         dataLevelThree = [];
    //         for (j = 0; j < levelthree.length; j++) {
    //           dataLevelThree.push({
    //             id: 1 + i + 1 + j + "digital",
    //             title: levelthree[j].name,
    //             value: levelthree[j].value,
    //             color: this.colors[j],
    //           });
    //         }
    //         secondLevelData.push({
    //           id: 1 + i + 1 + "digital",
    //           title: digitalLevelTwo[i].label,
    //           value: digitalLevelTwo[i].value,
    //           color: digitalLevelTwo[i].backgroundColor,
    //           data: dataLevelThree,
    //         });
    //       }
    //     }
    //     data.push({
    //       id: index + 1,
    //       title: ele.name,
    //       value: ele.predicted,
    //       color: this.colors[index],
    //       data: secondLevelData,
    //     });
    //   });
    // this.levelOne = data;
  },
  computed: {
    subsubStyle() {
      let style;
      if (
        this.activeGraph.activeFirst === 0 &&
        this.activeGraph.activeSecond === 0
      ) {
        style = `top: 0px;`;
      } else {
        if (
          (this.activeGraph.activeFirst > 0 &&
            this.activeGraph.activeSecond === 0) ||
          (this.activeGraph.activeFirst > 0 &&
            this.activeGraph.activeSecond > 0)
        ) {
          style =
            `top:` +
            336 *
            (this.activeGraph.activeSecond + this.activeGraph.activeFirst) +
            `px;`;
        } else {
          // style = `top:`+ (336*(this.activeGraph.activeSecond+1) + 6)+`px;`;
          style = `top:` + (334 * this.activeGraph.activeSecond + 6) + `px;`;
        }
      }
      return style;
    },
    checkLevelThree() {
      var sum = 0;
      for (var i = 0; i < this.levelOne.length; i++) {
        for (var j = 0; j < this.levelOne[i].data.length; j++) {
          sum = 0;
          for (var k = 0; k < this.levelOne[i].data[j].data.length; k++) {
            sum += this.levelOne[i].data[j].data[k].value;
            if (k === this.levelOne[i].data[j].data.length - 1) {
              if (sum !== 100) {
                return {
                  name: this.levelOne[i].data[j].data[k].title,
                  value: sum,
                };
              }
            }
          }
        }
      }
      return { name: "", value: sum };
    },
    checkLevelTwo() {
      var sum = 0;
      for (var i = 0; i < this.levelOne.length; i++) {
        sum = 0;
        for (var j = 0; j < this.levelOne[i].data.length; j++) {
          sum += this.levelOne[i].data[j].value;
        }
        if (sum !== 100) {
          return { name: this.levelOne[i].title, value: sum };
        }
      }
      return { name: "", value: sum };
    },
    levelOneChecking() {
      var sum = 0;
      for (var i = 0; i < this.levelOne.length; i++) {
        sum += this.levelOne[i].value;
        if (i === this.levelOne.length - 1) {
          if (sum !== 100) {
            return { name: "Level One", value: sum };
          }
        }
      }
      return { name: "", value: sum };
    },
    levelOneInputCheck() {
      var sum = 0;
      for (var i = 0; i < this.incommingData.length; i++) {
        sum += this.levelOne[i].number;
        if (i === this.levelOne.length - 1) {
          return { name: "LevelOne", value: sum };
        }
      }
      return { name: "", value: sum };
    },

    isActive() {
      var edited = sessionStorage.getItem("valueEdited");
      if (edited === "edited") {
        return false;
      }
      return true;
    },
  },
  methods: {
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    percentage(num, per) {
      return (num / 100) * per;
    },
    formatValue(data) {
      return abbreviateNumber(data, 5, {
        symbols: ["", "k", "M", "B", "T", "P", "E"],
      });
    },
    selectBrand(e) {
      this.selectedBrand = e;
    },
    selectMedia(e) {
      this.selectedMedia = e;
      // this.addAllTimeData();
      // this.addsalseStatics();
      // this.callApis();
    },
    handleSwitch() {
      if (this.showInPercentage === true) {
        this.showInPercentage = false;
        this.showInValue = true;
        this.switchText = "percentage";
      } else if (this.showInValue === true) {
        this.showInValue = false;
        this.showInPercentage = true;
        this.switchText = "numbers";
      }
    },
    getSliderData() {
      this.incommingData = JSON.parse(localStorage.getItem("optimizeData"));

      const forecastingPlanningData = this.$router.history.current.params.forecastingDataOptimized;

      forecastingPlanningData !== "" && forecastingPlanningData !== undefined ? this.isForecasting = true : this.isForecasting = false


      // let optimisedData = [

      // ]

      let data = [];

      const res = this.incommingData;

      var levelOneColor = ["rgb(106,27,154)", "rgb(216,27,96)"];
      res.mediaData.forEach((ele, index) => {
        var dataLevelThree = [];
        var secondLevelData = [];
        var dataLevelFour = [];
        var i;
        var j;
        var k;
        for (i = 0; i < ele.levelOne.length; i++) {
          let levelthree = ele.levelTwo.find(
            (obj) => obj.name === ele.levelTwo[i].name
          ).value;

          let levelFour = ele.levelThreeList.find(
            (obj) => obj.name === ele.levelThreeList[i].name
          ).value;

          dataLevelThree = [];
          dataLevelFour = [];

          for (k = 0; k < levelFour.length; k++) {
            dataLevelFour.push({
              id: 1 + i + 1 + j + k + ele.name,
              number: levelFour[k].optimised,
              title: levelFour[k].name,
              value: this.formatValue(levelFour[k].optimised),
              previous: levelFour[k].previous,
              color: this.colors[k],
            });
          }


          for (j = 0; j < levelthree.length; j++) {
            dataLevelThree.push({
              id: 1 + i + 1 + j + ele.name,
              number: levelthree[j].optimised,
              title: levelthree[j].name,
              value: this.formatValue(levelthree[j].optimised),
              previous: levelthree[j].previous,
              color: this.colors[j],
              data: dataLevelFour,
            });
          }

          // dataLevelFour.push({
          //   id: 1 + i + 1 + ele.title,
          //   title: levelFour[j].name,
          //   number: levelFour[j].optimised,
          //   value: this.formatValue(levelFour[j].optimised),
          //   previous: levelFour[j].previous,
          // })

          // console.log("dataLevelThree 123", dataLevelThree)
          secondLevelData.push({
            id: 1 + i + 1 + ele.title,
            title: ele.levelOne[i].name,
            number: ele.levelOne[i].optimised,
            value: this.formatValue(ele.levelOne[i].optimised),
            previous: ele.levelOne[i].previous,
            color: this.getColor(levelOneColor[index], i),
            data: dataLevelThree,
          });
        }

        data.push(...secondLevelData);
      });
      // });
      this.levelOne = data;
      // console.log("levelOne", this.levelOne[0].data);
    },
    getColor(color, index) {
      var opacity;
      if (index >= 5) {
        opacity = 0.5;
      } else {
        opacity = (10 - index) / 10;
      }
      var x = color.split(")");
      return x[0] + "," + opacity + ")";
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    toggleOpenButton(index) {
      this.activelevelOne = this.levelOne[index].title;
      if (!this.showMap[index]) {
        this.activeGraph.activeFirst = index;
        this.showMap = [];
        this.showMap[index] = true;
        this.breadcrumb.second = "Ad type";
      } else {
        this.activeGraph.activeFirst = null;
        this.showMap[index] = false;
        this.breadcrumb.second = "";
      }
    },
    toggleOpenSubButton(index) {
      if (!this.showMapSub[index]) {
        this.activeGraph.activeSecond = index;
        this.showMapSub = [];
        this.showMapSub[index] = true;
        this.breadcrumb.third = "Campaign type ";
      } else {
        this.activeGraph.activeSecond = null;
        this.showMapSub[index] = false;
        this.breadcrumb.third = "";
      }
      this.activeLevelTwo = this.levelOne[this.activeGraph.activeFirst].data[
        index
      ].title;
    },
    getSliderValue(e, data) {
      sessionStorage.setItem("edited", "edited");
      this.levelOne.find((obj) => obj.title === data.title).value = e;
      this.levelOneDataEdit.find(
        (obj) => obj.name === data.title
      ).predicted = e;
    },
    getSliderValueLevelTwo(e, details) {
      sessionStorage.setItem("edited", "edited");
      let levelTwoData = this.levelOne.find(
        (obj) => obj.title === this.activelevelOne
      ).data;
      levelTwoData.find((obj1) => obj1.title === details.title).value = e;
      if (this.activelevelOne === "Digital") {
        this.digitalDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      } else if (this.activelevelOne === "Content") {
        this.contentDataLevelTwo.find(
          (objedit) => objedit.label === details.title
        ).value = e;
      }
    },
    getSliderValueLevelThree(e, details) {
      sessionStorage.setItem("edited", "edited");
      let levelTwoData = this.levelOne.find(
        (obj) => obj.title === this.activelevelOne
      ).data;
      let levelThreeData = levelTwoData.find(
        (obj1) => obj1.title === this.activeLevelTwo
      ).data;
      levelThreeData.find((obj3) => obj3.title === details.title).value = e;
      if (this.activelevelOne === "Digital") {
        let dataEdit = this.digitalDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      } else if (this.activelevelOne === "Content") {
        let dataEdit = this.contentDataThirdLevel.find(
          (title) => title.title === this.activeLevelTwo
        ).value;
        dataEdit.find((editObj) => editObj.name === details.title).value = e;
      }
    },
    handleClick() {

      let data = [];
      data.push({
        runId: sessionStorage.getItem("subId"),
        clientId: sessionStorage.getItem("subId"),
        percentage: false,
        brand: this.selectedBrand.text,
        kpi: this.selectedMedia.text,
        redistribution: this.incommingData.mediaData,
        values: {
          mediaData: this.scearioData.mediaData,
          predictions: this.incommingData.predictions,
        },
      });



      // let data = [];
      // data.push({
      //   runId: sessionStorage.getItem("subId"),
      //   percentage: false,
      //   brand: this.selectedBrand.text,
      //   kpi: this.selectedMedia.text,
      //   redistribution: this.incommingData.mediaData,
      //   values: {
      //     mediaData: this.scearioData.mediaData,
      //     predictions: this.incommingData.predictions,
      //   },
      // });

      aimoUnileverService.scenarioPlanningV2(...data).then((res) => {
        const res_data = [
          {
            redistribution: res.mediaData,
            values: {
              mediaData: res.mediaData,
              predictions: res.predictions,
            },
          },
        ];
        this.$router.push({
          name: "PlanningResults Optimized UL",
          params: { optimizedBudgetData: res_data },
        });
      }).catch((err) => {
        let msg = err.correctionLevel.split("|")
        this.toastData = {
          show: true,
          message: `Budget in ${msg[2]} is not matching by ${this.formatValue(msg[1])} in ${msg[0]} `,
          type: "",
        };
      });
      // this.$router.push("/solutions/ul/future-planning/planning-results");
    },
    getLevelOneInput(e) {
      sessionStorage.setItem("valueEdited", "edited");
      this.incommingData.mediaData[0].levelOne.find(
        (obj) => obj.name === this.activelevelOne
      ).optimised = e.target.value * 1000000;
    },
    getLevelTwoInput(e) {
      sessionStorage.setItem("valueEdited", "edited");
      var x = this.incommingData.mediaData[0].levelTwo.find(
        (obj) => obj.name === this.activelevelOne
      ).value;
      x.find((obj1) => obj1.name === this.activeLevelTwo).optimised =
        e.target.value * 1000000;
    },
    getLevelThreeInput(e, data) {
      sessionStorage.setItem("valueEdited", "edited");
      var s = this.incommingData.mediaData[0].levelThreeList.find(
        (obj) => obj.name === this.activeLevelTwo
      ).value;
      s.find((obj1) => obj1.name === data.name).optimised = e.target.value * 1000000;
    },
    // handleClick() {
    //   this.buttonLoader = "loader";
    //   var redistributionData = [];
    //   this.levelOne.forEach((obj) => {
    //     var levelOne = [];
    //     var levelTwo = [];
    //     for (var i = 0; i < obj.data.length; i++) {
    //       var finalData = [];
    //       for (var j = 0; j < obj.data[i].data.length; j++) {
    //         finalData.push({
    //           name: obj.data[i].data[j].title,
    //           optimised: obj.data[i].data[j].value,
    //           previous: obj.data[i].data[j].previous
    //         });
    //         if (j === obj.data[i].data.length - 1) {
    //           levelTwo.push({ title: obj.data[i].title, value: finalData });
    //         }
    //       }
    //       levelOne.push({ name: obj.data[i].title, optimised: obj.data[i].value,previous:obj.data[i].previous });
    //     }
    //     redistributionData.push({
    //       title: obj.title,
    //       optimised: obj.value,
    //       levelOne: levelOne,
    //       levelTwo: levelTwo,
    //     });
    //   });
    //   var data = {
    //     runId: sessionStorage.getItem("runId"),
    //     redistribution: redistributionData,
    //     values: this.actualValues,
    //     target: sessionStorage.getItem("targetVariable"),
    //   };
    //   aiDigitalPoem
    //     .getDataFromScenario(data)
    //     .then((res) => {
    //       this.buttonLoader = "normal";
    //       this.$router.push({
    //         name: "AI-Digital Poem Planning Result",
    //         params: {
    //           response: res,
    //         },
    //       });
    //     })
    //     .catch(() => {
    //       this.buttonLoader = "normal";
    //     });
    // },
  },
};
</script>

<style scoped>
.w-99 {
  width: 99%;
}

.chart-container {
  background: transparent !important;
}

.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}

.caption {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727988;
}

.newcard {
  width: 352px;
  height: 180px;
  margin: 24px;
  border-radius: 6px;
  padding: 17px 0px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #fff;
  margin: auto;
  margin-bottom: 24px;
}

.slider_text {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
}

.slider_button_right {
  background: #f3f3f3;
  padding: 5px;
  border-radius: 4px;
}

.text-normal {
  font-weight: normal;
  font-size: 16px;
}

.bottom_header {
  position: sticky;
  top: 100px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 9px 9px 0px;
}

.bottom_buttom {
  width: 100%;
  text-align: end;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: #f2f5fa;
}

.btn-effect {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect-valid {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover,
.btn-effect.active {
  background-color: #050505;
  color: #f3f3f3;
}

.border_right_side::before {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: 100%;
  top: 50%;
  width: 31%;
}

.border_left_side::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -7%;
  bottom: 129.5%;
  width: 7%;
}

.border_left_side1::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -10%;
  top: -29%;
  width: 10%;
}

.border-dark {
  border: 2px solid #000 !important;
}

.disabled_card {
  background: #ffffff;
  opacity: 0.5;
}

.disabled_card.default {
  opacity: 1;
  background: #fff;
}

.divider {
  border-right: 2px solid #000;
  bottom: 125.5%;
  display: block;
  left: 31%;
  position: relative;
}

.divider1 {
  border-right: 2px solid #000;
  top: -24%;
  display: block;
  left: 28%;
  position: relative;
}

.text-bold {
  font-weight: bold;
}

.card__body_content {
  overflow-y: auto;
  height: 85%;
  overflow-x: auto;
  box-shadow: rgb(233 235 239) -2px 4px 20px 11px;
  width: 100%;
}

.fix-width {
  max-width: 1600px;
  width: 100%;
}

.prediction-section {
  overflow: hidden;
  height: 70%;
  padding: 0 10px;
}

@media screen and (max-width: 640px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}

.dataCard {
  max-width: 300px;
  width: 100%;
  height: 50px;
  margin-left: 14px;
  margin-top: 10px;
  margin-bottom: 100px;
  padding: 13px;
  border-radius: 3px;
  background-color: #ffffff;
}

.dataCard a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.Last-year-title {
  font-family: ProximaNovaRegular;
  color: #727988;
  font-size: 16px;
}

.Last-year-value {
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  font-weight: 600;
  color: #050505;
}

.line {
  width: 1px;
  margin: 0 11px;
  border: solid 1px #c6ccd6;
}
</style>
